.header {
  padding-left: 2.5%;
  padding-right: 2.5%;
  width: 100%;
  height: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
  }

  &_logo {
    color: #000000;
    font-size: 28px;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #40a9ff;
    }
  }

  &_nav {
    width: 100%;
    display: flex;

    @media (max-width: 500px) {
      justify-content: space-between;
      padding-top: 10px;
    }

    nav {
      font-size: 28px;
      width: 100%;
      display: flex;
      justify-content: center;

      @media (max-width: 500px) {
        justify-content: flex-start;
      }

      a {
        color: #000000;
        text-decoration: none;
        transition: color 0.3s;
        margin-left: 10px;
        margin-right: 10px;

        @media (max-width: 500px) {
          font-size: 24px;
          line-height: 30px;
          margin-left: 0px;
        }

        &:hover {
          color: #40a9ff;
        }

        &.active {
          color: #096dd9;
          border-bottom: solid 2px #096dd9;
        }
      }
    }

    &_logout {
      display: flex;
      align-items: center;

      &_btn {
        display: flex;
        align-items: center;

        div {
          display: flex;
        }

        span {
          font-size: 18px;
        }
      }

      p {
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}
