.list_invoice {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  &_btn {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    &_text {
      margin-left: 8px;
    }
  }
}
