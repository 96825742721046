.authorization {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &_title {
    font-size: 42px;
    margin-bottom: 20px;
  }

  &_btn {
    display: flex;
    align-items: center;

    div {
      display: flex;
    }

    span {
      font-size: 18px;
    }
  }
}
