.form_invoice {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 500px) {
    padding-top: 10px;
  }
}
